import * as DateFns from 'date-fns';
import { useGroup } from '../util/crossfilter';


// Returns aggregate for current and previous time period.
//
// Returns an object { current, previous }.
//
// Each aggregate has { promoters, passives, detractors, responded, score }.
export default function useAggregates({ dimension, reducers, dateRange }) {
  const group         = useGroup({ dimension, reducers });
  const previousRange = getPreviousRange(dateRange);

  const current  = addInRange({ group, dateRange, reducers });
  const previous = addInRange({ group, dateRange: previousRange, reducers });

  return { current, previous };
}


// Returns the previous range with the same number of days as the current range.
//
// getPreviousRange({ startDate: '2024-08-11', endDate: '2024-08-17' })
//  => { startDate: '2024-08-04', endDate: '2024-08-10' }
//
// getPreviousRange({ startDate: '2024-08-11', endDate: '2024-08-11' })
//  => { startDate: '2024-08-10', endDate: '2024-08-10' }
function getPreviousRange(currentRange) {
  // DateFns needs Date objects.
  const startDate = DateFns.parseISO(currentRange.startDate);
  const endDate   = DateFns.parseISO(currentRange.endDate);

  // Times are removed from the dates when the difference is calculated.
  const differenceInDays = DateFns.differenceInCalendarDays(endDate, startDate);

  const previousEndDate   = DateFns.subDays(startDate, 1);
  const previousStartDate = DateFns.subDays(previousEndDate, differenceInDays);

  return {
    startDate: DateFns.format(previousStartDate, 'yyyy-MM-dd'),
    endDate:   DateFns.format(previousEndDate, 'yyyy-MM-dd')
  };
}


function addInRange({ group, dateRange, reducers }) {
  const addReducer     = reducers[0];
  const initialReducer = reducers[2];
  return group.all()
    .filter(function({ key: date }) {
      return date >= dateRange.startDate && date <= dateRange.endDate;
    })
    .map(({ value }) => value)
    .reduce(addReducer, initialReducer());
}
