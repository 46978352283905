import './team.less';

import * as d3                     from 'd3';
import { alphaNumericCompare }     from '../util/sort';
import { getLocationDisplayName }  from '../util/locations';
import { noOrgID }                 from '../app/routes';
import { roundRating }             from '../metrics/reviews';
import { useMemo }                 from 'react';
import { useNotification }         from '../components/notification';
import { useOnChange }             from '../util/crossfilter';
import { useParams }               from 'react-router';
import { useSearchParameterState } from '../util/use_search_params';
import { useState }                from 'react';
import { WideBlock }               from '../components/block';
import Block                       from '../components/block';
import Facets                      from './facets';
import get                         from 'lodash.get';
import getFilterDate               from '../util/filter_date';
import LoadingShimmer              from '../components/loading_shimmer';
import React                       from 'react';
import SortableTable               from '../components/sortable_table';
import useDateDimension            from '../hooks/use_date_dimension';
import useDateRange                from '../hooks/use_date_range';
import useLocations                from '../util/use_locations';
import useProviderMetrics          from './use_provider_metrics';
import useProviders                from '../util/use_providers';
import useSelectedLocations        from '../util/use_selected_locations';

import { ExternalLink }            from 'react-feather';
import { Frown }                   from 'react-feather';
import { Meh }                     from 'react-feather';
import { Smile }                   from 'react-feather';
import { ThumbsDown }              from 'react-feather';
import { ThumbsUp }                from 'react-feather';
import { useLogPageViewEvent }     from '../util/use_log_page_view_event';


export default function TeamPage() {
  const [ column, setColumn ]       = useState('name');
  const [ direction, setDirection ] = useState('descending');
  const selectedLocations           = useSelectedLocations();
  const locationsMap                = useLocations();
  const { showError }               = useNotification();
  const { minDate, maxDate }        = getFilterDate();
  const [ dateRange, setDateRange ] = useDateRange({ maxDate });

  const [ activityFilter = 'true', setActivityFilter ] = useSearchParameterState('activity_filter');
  const activityFilterEnabled                          = (activityFilter === 'true');

  function onClickActivityFilterCheckbox({ target }) {
    setActivityFilter(String(target.checked));
  }

  const {
    cf: providerMetricsCf,
    error: providerMetricsError,
    isLoading: providerMetricsIsLoading
  } = useProviderMetrics({ ...dateRange });
  const {
    providers: providerList,
    error: providersError,
    isLoading: providersIsLoading
  } = useProviders();


  const isLoading                = providersIsLoading || providerMetricsIsLoading;
  const providerMetricsDimension = useDateDimension(providerMetricsCf, dateRange);
  const providers                = getProviders({
    providerMetricsDimension,
    providerList,
    locationsMap,
    column,
    direction,
    selectedLocations,
    activityFilterEnabled
  });
  useOnChange(providerMetricsCf);
  useLogPageViewEvent('DashboardTeamViewed');

  if (providerMetricsError || providersError) {
    console.error(providerMetricsError || providersError);
    showError('Something went wrong. Refresh the page to try again.');
    return null;
  }
  return (
    <div className="team-page">
      <Block>
        <Facets {...{ cf: providerMetricsCf, dateRange, minDate, maxDate, setDateRange, providers, isLoading }}/>
        <div className="team-header">
          <h2>Team Performance</h2>
          <div className="checkbox actvity-filter-checkbox">
            <label htmlFor="actvity-filter-checkbox"><input
              id="actvity-filter-checkbox"
              type="checkbox"
              checked={activityFilterEnabled}
              onChange={onClickActivityFilterCheckbox}
            /> Hide team members with no activity</label>
          </div>
        </div>
        {isLoading && <LoadingShimmer/>}
      </Block>
      <WideBlock>
        {!isLoading && <TeamTable {...{ providers, column, setColumn, direction, setDirection }}/>}
      </WideBlock>
      {!isLoading && <Copy/>}
    </div>
  );
}

function TeamTable({ providers, column, setColumn, direction, setDirection }) {
  const { organizationID = noOrgID } = useParams();
  const { Cell }                     = SortableTable;
  const optionalColumns              = [ 'feedback.npsScore', 'feedback.satisfactionScore', 'feedback.passives' ];
  const columnsInUse                 = getColumnsInUse(providers, optionalColumns);
  const switchSmileysToThumbs        = columnsInUse.includes('feedback.satisfactionScore') && !columnsInUse.includes('feedback.npsScore');
  const searchParams                 = document.location.search.slice(1);
  const providerNameCountMap         = useMemo(() => getProvidersNameCountMap(providers), [ providers ]);

  function tableHeader(columnName, displayName, className = false) {
    return (
      <th
        onClick={handleSort(columnName)}
        active={column === columnName}
        {...(className && { className })}
      >
        {displayName}
      </th>
    );
  }

  function handleSort(newColumn) {
    return () => {
      if (newColumn === column)
        setDirection(direction === 'ascending' ? 'descending' : 'ascending');
      else
        setColumn(newColumn);
    };
  }

  return (
    <SortableTable>
      <SortableTable.Head {...{ direction }}>
        {tableHeader('name', <>Team<br/>Member</>)}
        {tableHeader('feedbackResponse.asked', <>Customers<br/>Asked</>, 'fixed-width')}
        {tableHeader('feedbackResponse.responseRate', <>Response<br/>Rate</>, 'fixed-width')}
        {tableHeader('feedback.promoters', switchSmileysToThumbs ? <ThumbsUp/> : <Smile/>, 'borderless fixed-width')}
        {(columnsInUse.includes('feedback.passives') || columnsInUse.includes('feedback.npsScore')) && (
          tableHeader('feedback.passives', <Meh/>, 'borderless fixed-width')
        )}
        {tableHeader('feedback.detractors', switchSmileysToThumbs ? <ThumbsDown/> : <Frown/>, 'borderless fixed-width')}
        {columnsInUse.includes('feedback.npsScore') && (
          tableHeader('feedback.npsScore', <>NPS<br/>Score</>)
        )}
        {columnsInUse.includes('feedback.satisfactionScore') && (
          tableHeader('feedback.satisfactionScore', <>Satisfaction<br/>Score</>)
        )}
        {tableHeader('feedbackResponse.reviews', <>Reviews</>)}
        {tableHeader('feedbackResponse.rating', <>Average Rating</>)}
      </SortableTable.Head>
      <SortableTable.Body>
        {providers.map(({ id, name, feedback, feedbackResponse, user, location }) => {
          // User will have different provider IDs in each business. We want to
          // show them only once, so use user ID if available.
          const isUser   = !!user;
          const model    = isUser ? 'user' : 'provider';
          const memberID = isUser ? user.id : id;
          const memberQS = `${model}:${memberID}`;

          const { asked }             = feedbackResponse;
          const { responseRate }      = feedbackResponse;
          const { reviews }           = feedbackResponse;
          const { rating }            = feedbackResponse;
          const responseRateFormatted = responseRate && d3.format('.0%')(responseRate);
          const providerDisplayName   = getProviderDisplayName({ name, location, providerNameCountMap });
          const urlToFeedback         = `/${organizationID}/feedback?member=${memberQS}${searchParams ? '&'.concat(searchParams) : ''}`;

          return (
            <tr key={id}>
              <Cell className="truncated-cell" href={urlToFeedback}>{providerDisplayName} <ExternalLink/></Cell>
              <Cell className="fixed-width">{asked || null}</Cell>
              <Cell className="fixed-width">{responseRateFormatted || null}</Cell>
              <Cell className="fixed-width">{feedback.promoters || null}</Cell>
              {(columnsInUse.includes('feedback.passives') || columnsInUse.includes('feedback.npsScore')) && (
                <Cell className="fixed-width">{feedback.passives || null}</Cell>
              )}
              <Cell className="fixed-width">{feedback.detractors || null}</Cell>
              {columnsInUse.includes('feedback.npsScore') && (
                <Cell className="fixed-width">{feedback.npsScore || null}</Cell>
              )}
              {columnsInUse.includes('feedback.satisfactionScore') && (
                <Cell className="fixed-width">{feedback.satisfactionScore ? `${feedback.satisfactionScore}%` : null}</Cell>
              )}
              <Cell className="fixed-width">{reviews || null}</Cell>
              <Cell className="fixed-width">{rating || null}</Cell>
            </tr>
          );
        })}
      </SortableTable.Body>
    </SortableTable>
  );
}

export function getProviders({
  providerMetricsDimension,
  providerList,
  locationsMap,
  column,
  direction,
  selectedLocations,
  activityFilterEnabled
}) {
  const providerMetricsMap = groupMetricsByProvider(providerMetricsDimension.top(Infinity));

  const sortedProviders = [ ...locationsMap.keys() ]
    .filter(key => selectedLocations.size === 0 || selectedLocations.has(key))
    .reduce((providersAcc, key) => {
      const location = locationsMap.get(key);

      if (location) {
        const providers = providerList.filter(provider => provider.location.id === location.id);
        const isNPS     = location.askForFeedback === 'NPS';

        const providersWithData = providers.map(provider => {
          const defaultProviderMetrics = {
            feedbackResponse: {
              asked:        0,
              responded:    0,
              responseRate: 0,
              reviews:      0,
              rating:       null
            },

            classifiedAs: {
              promoters:  0,
              passives:   0,
              detractors: 0
            }
          };

          const { feedbackResponse, classifiedAs } = providerMetricsMap.get(provider.id) || defaultProviderMetrics;
          const score                              = getScore({ ...classifiedAs, isNPS });

          return {
            ...provider,
            feedback: {
              ...classifiedAs,
              // Split score columns into satisfactionScore/npsScore
              satisfactionScore: isNPS ? null : score,
              npsScore:          isNPS ? score : null
            },
            feedbackResponse,
            location
          };
        });

        return providersAcc.concat(providersWithData);
      }

      return providersAcc;
    }, [])
    .sort((a, b) => {
      const rowA      = get(a, column);
      const rowB      = get(b, column);
      const ascending = direction === 'ascending';

      return alphaNumericCompare(rowA, rowB, ascending);
    });

  const filteredProviders = activityFilterEnabled ? sortedProviders.filter(filterProvidersWithNoActivity) : sortedProviders;

  // "Name" is a text field, which descedning order should be A - Z
  if (column === 'name')
    return filteredProviders.reverse();
  else
    return filteredProviders;
}

function filterProvidersWithNoActivity(provider) {
  const values = [
    ...Object.values(provider.feedback),
    ...Object.values(provider.feedbackResponse)
  ];
  return values.some(value => value > 0);
}

function getScore({ promoters, passives, detractors, isNPS }) {
  if (isNPS) {
    const responded = promoters + passives + detractors;
    const score     = responded ? Math.round((promoters - detractors) / responded * 100) : 0;
    return score;
  } else {
    const responded = promoters + detractors;
    const score     = responded ? Math.round(promoters / responded * 100) : 0;
    return score;
  }
}

function groupMetricsByProvider(providerMetrics) {
  return providerMetrics.reduce((map, metrics) => {
    const { providerID }       = metrics;
    const { feedbackResponse } = metrics;
    const defaultClassifiedAs  = { promoters: 0, passives: 0, detractors: 0 };
    const classifiedAs         = metrics.classifiedAs || defaultClassifiedAs;

    const defaultMetrics = {
      feedbackResponse: { asked: 0, responded: 0, responseRate: 0, reviews: 0, rating: null },
      classifiedAs:     defaultClassifiedAs
    };

    const previous     = map.get(providerID) || defaultMetrics;
    const promoters    = previous.classifiedAs.promoters + classifiedAs.promoters;
    const passives     = previous.classifiedAs.passives + classifiedAs.passives;
    const detractors   = previous.classifiedAs.detractors + classifiedAs.detractors;
    const asked        = previous.feedbackResponse.asked + feedbackResponse.asked;
    const responded    = previous.feedbackResponse.responded + feedbackResponse.responded;
    const reviews      = previous.feedbackResponse.reviews + feedbackResponse.reviews;
    const rating       = roundRating(getAverageRating({ previous, feedbackResponse, reviews }));
    const responseRate = asked ? (responded / asked) : 0;

    return map.set(providerID, {
      feedbackResponse: {
        asked,
        responded,
        responseRate,
        reviews,
        rating
      },

      classifiedAs: {
        promoters,
        passives,
        detractors
      }
    });
  }, new Map());
}

function getAverageRating({ previous, feedbackResponse, reviews }) {
  if (previous.feedbackResponse.rating) {
    return (
      (previous.feedbackResponse.reviews * previous.feedbackResponse.rating) +
      (feedbackResponse.reviews * feedbackResponse.rating)
    ) / reviews;
  } else
    return feedbackResponse.rating;
}

function getColumnsInUse(data, optionalColumns) {
  return data.reduce((acc, location) => {
    if (optionalColumns.length === acc.length)
      return acc;

    optionalColumns.forEach(column => {
      const value = get(location, column);

      if (!acc.includes(column) && value)
        acc.push(column);
    });

    return acc;
  }, []);
}

function Copy() {
  return (
    <Block>
      <p className="copy">Track team member performance, to ensure a positive customer experience.<br/>Don’t see your team? <a
        href="https://help.broadly.com/hc/en-us/articles/20504808263831-Adding-Editing-Team-Members"
        rel="noopener noreferrer"
        target="_blank"
      >Learn how to match customer satisfaction with your team members.</a></p>
    </Block>
  );
}

export function getProvidersNameCountMap(providers) {
  return providers.reduce((acc, provider) => {
    const name  = provider.name;
    const count = acc.get(name);
    if (count)
      acc.set(name, count + 1);
    else
      acc.set(name, 1);
    return acc;
  }, new Map());
}

export function getProviderDisplayName({ name, location, providerNameCountMap }) {
  if (providerNameCountMap.get(name) > 1) {
    const locationName = getLocationDisplayName(location);
    return `${name} - ${locationName}`;
  } else
    return name;
}
